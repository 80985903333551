import React, { useEffect } from 'react';

import { appName } from '../config';

import './terms.scss';

export default () => {
  /** Reset to the top scroll  */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="legal">
      <h2>Políticas de privacidad</h2>
      <span>
        El Usuario puede utilizar la aplicación con el propósito de acceder a la información que en ella se presenta y
        puede eventualmente compartirla. Al suministrar información, el Usuario asiste al Administrador en el constante
        proceso de mejora de los servicios disponibles y en el desarrollo de nuevos productos, siempre tendiendo a que
        el uso de los mismos se torne más sencillo y ágil para los Usuarios. Proporcionar datos personales supone la
        aceptación sin reservas de los términos y condiciones aquí establecidos. Si tiene dudas al respecto, póngase en
        contacto con el Administrador a través de sintesisproductiva@presidencia.gob.ar.
      </span>

      <h3>Información al Usuario titular de los datos personales</h3>
      <span>
        El Usuario que utilice la aplicación podrá proporcionar algunos datos personales a efectos de mantenerse en
        contacto y recibir información respecto de servicios que se pongan a disposición de la persona en relación a los
        servicios utilizados. <br />
        La Subsecretaría de Gobierno Digital, con domicilio legal en Av. Roque Sáenz Peña 511, es la responsable de las
        Bases de Datos a las que se incorporarán los datos personales suministrados. Las mismas han sido debidamente
        registradas ante la Dirección Nacional de Protección de Datos Personales en los términos de la Ley de Protección
        de Datos Personales, Nº 25.326. <br />
        El Administrador se ocupa de que el Usuario cuente con la información veraz y necesaria para decidir de forma
        expresa, libre y voluntaria si desea brindar sus datos personales, informando a tal efecto las consecuencias de
        hacerlo, de la negativa y de la inexactitud de los mismos. <br />
        En ningún caso proporcionar los datos personales es condición para el uso la aplicación y sus servicios
        vinculados. No obstante, si el Usuario decide voluntariamente proporcionar sus datos personales debe brindar
        datos veraces, exactos y completos. La inexactitud de los mismos puede suponer dificultades para establecer un
        vínculo directo con el Administrador. <br />
        La Subsecretaría de Gobierno Digital conservará los datos personales brindados por los Usuarios hasta el momento
        en que se encuentre extinguida la finalidad para la cual fueron recolectados o hasta tanto el titular de dichos
        datos lo solicite. <br />
        El responsable de las Bases de datos puede también ceder los datos personales recolectados a otras Dependencias
        y Organismos dependientes del Sector Público Nacional siempre que tal cesión se corresponda con las competencias
        de la Dependencia u Organismo cesionario, en los términos del Artículo 11 inc.3 ap. b y c de la Ley Nº 25.326.{' '}
        <br />
        El titular de los datos personales podrá solicitar la actualización, rectificación y, cuando corresponda, la
        supresión o el sometimiento a confidencialidad de los datos personales provistos, pudiendo acceder en forma
        gratuita, a intervalos no inferiores a seis meses, a la Base de datos habilitada, la cual se encuentra
        debidamente inscripta por la Disposición 9 - E/2016, de la Subsecretaría de Gobierno Digital, parte de la
        Secretaría de Gestión e Innovación Pública, del Ministerio de Modernización en los términos de la Ley de
        Protección de Datos Personales, Nº 25.326, y sus normas complementarias, salvo que se acredite un interés
        legítimo a tal fin, conforme lo establecido en el artículo 14, inciso 3 de la Ley N° 25.326. <br />
        La Dirección Nacional de Protección de Datos Personales, órgano de control de la Ley N° 25.326, tiene la
        atribución de atender las denuncias y reclamos que se formulen con relación al incumplimiento de las normas
        sobre protección de datos personales.
      </span>

      <h3>Finalidad de los datos recolectados</h3>
      <span>
        Los datos recolectados tendrán por finalidad:
        <ul>Optimizar los servicios brindados por el Gobierno Nacional.</ul>
        <ul>
          Compararlos con los registros de los servicios del Gobierno Nacional para ofrecer informaciones relevantes al
          Usuario.
        </ul>
        <ul>
          Enviar a las personas alertas por SMS o e-mail, respecto a los servicios utilizados y sobre los cuales
          solicitó dichas notificaciones como, por ejemplo, un vencimiento.
        </ul>
        <ul>Personalizar el contenido visualizado por el Usuario de los Servicios Digitales del Gobierno Nacional.</ul>
        <ul>Obtener métricas de uso y niveles de servicio del Gobierno Nacional.</ul>
      </span>

      <h3>Seguridad</h3>
      <span>
        El responsable de la Base de Datos declara que adopta las medidas técnicas y organizativas que resulten
        necesarias para garantizar la seguridad y confidencialidad de los datos personales de modo de evitar su
        adulteración, pérdida, consulta o tratamiento no autorizado y que permitan detectar desviaciones de información.
        <br />
        Asimismo, el Gobierno Nacional protege los Datos Personales de tal forma que no puedan ser eliminados
        accidentalmente. Por ese motivo, aunque el Usuario los elimine, es posible que subsistan copias residuales
        almacenadas en los sistemas de seguridad.
        <br />
        Para proteger al Gobierno Nacional y a los Usuarios de cualquier modificación, divulgación o destrucción no
        autorizada se toman, entre otros, los siguientes recaudos:
        <ul>Encriptación de las comunicaciones/transferencia de datos mediante protocolo TLS/SSL.</ul>
        <ul>Encriptación de las Bases de datos sensibles o considerados críticas.</ul>
        <ul>Entorno seguro para el equipamiento y los sistemas utilizados para almacenar los datos de los usuarios.</ul>
        <ul>
          Políticas definidas en materia de recolección, almacenamiento y tratamiento de datos, incluyendo medidas de
          seguridad física y controles de acceso a los sistemas que emplean los activos digitales.
        </ul>
        <ul>
          Acceso restrictivo a los datos por parte de terceros, basados en el principio de “necesidad de saber” y una
          adecuada separación de funciones, asegurando la suscripción y el cumplimiento de las condiciones de
          confidencialidad requeridas por los datos bajo tratamiento.
        </ul>
        <ul>Otras medidas de seguridad que resulten de aplicación en cada caso, conforme a la normativa vigente.</ul>
      </span>

      <h3>Uso general de {appName.toUpperCase()} APP</h3>
      <span>
        La utilización de los Servicios Digitales requiere que el Usuario suministre datos personales para su
        registración.
        <br />
        En ese caso, el Usuario proveerá:
        <br />
        Datos sobre el dispositivo: es posible recoger datos específicos sobre el dispositivo del Usuario como, por
        ejemplo, el modelo de equipo, la versión del sistema operativo, los identificadores únicos y los datos sobre la
        red móvil.
        <br />
        Datos sobre la ubicación física: al utilizar un activo digital que pueda registrar la ubicación física del
        Usuario, es posible llevar a cabo la recolección y el tratamiento de datos acerca de la ubicación real del
        Usuario. En el caso de no estar registrado el Usuario, estos datos son almacenados en forma anónima.
        <br />
        Cookies e identificadores anónimos: es posible utilizar diferentes tecnologías para recoger y almacenar datos
        cuando el Usuario accede a un servicio prestado por el Gobierno Nacional, incluyendo el envío de una o varias
        cookies o de identificadores anónimos a su dispositivo.
      </span>

      <h3>Uso general a través de cuenta en {appName.toUpperCase()} APP</h3>
      <span>
        La información personal del Usuario que opta por registrarse puede contener campos obligatorios: Nombres.
        <ul>Apellidos.</ul>
        <ul>Número de CUIL o Pasaporte.</ul>
        <ul>Dirección de correo electrónico.</ul>
        <ul>Fecha de nacimiento.</ul>
        Campos No Obligatorios:
        <ul>Nacionalidad.</ul>
        <ul>Provincia.</ul>
        <ul>Localidad.</ul>
        <ul>Teléfono móvil.</ul>
        <ul>Dirección.</ul>
        <ul>Código postal.</ul>
      </span>

      <h2>Datos sobre el dispositivo</h2>
      <span>
        El Administrador puede recoger datos específicos sobre el dispositivo mediante el cual se utilizan los Servicios
        Digitales como, por ejemplo, el modelo de equipo, la versión del sistema operativo, los identificadores únicos y
        los datos sobre la red móvil, incluyendo el número de teléfono.
        <br />
        Asimismo, el Administrador podrá asociar los identificadores del dispositivo o número de teléfono del Usuario
        con su cuenta en el sistema de identificación único.
      </span>

      <h3>Datos de Registro</h3>
      <span>
        El Administrador podrá obtener y almacenar automáticamente determinada información en sus registros cada vez que
        el Usuario utilice los servicios a su disposición o consulte contenidos.
        <br />
        Dicha información incluye:
        <ul>
          información detallada sobre la utilización de los servicios provistos como, por ejemplo, noticias consultadas;
        </ul>
        <ul>la dirección IP;</ul>
        <ul>
          cookies, datos que permitirán identificar el navegador o cuenta del Usuario en el sistema de identificación
          único.
        </ul>
        <ul>Datos sobre la ubicación física del usuario</ul>
        <ul>
          Al utilizar un activo digital que pueda registrar la ubicación física del Usuario, el Administrador puede
          recolectar y tratar datos acerca de su ubicación real. Estos datos son almacenados en forma anónima.
        </ul>
      </span>

      <h3>Cookies e identificadores anónimos</h3>
      <span>
        El Administrador utiliza diferentes tecnologías para recoger y almacenar datos cuando el Usuario accede a un
        servicio provisto por el Gobierno Nacional, incluyendo el envío de una o varias cookies o de identificadores
        anónimos al dispositivo del Usuario.
        <br />
        La vinculación entre los datos mencionados y la información que con el transcurso del tiempo se genere en el
        sistema sobre el Usuario acerca de noticias consultadas o servicios suscriptos, conforman un perfil acerca del
        Usuario.
      </span>

      <h3>Datos recolectados o provistos por terceros</h3>
      <span>
        Google Analytics
        <br />
        El Administrador recolecta información estadística anónima para la evaluación y mejora del servicio a través de
        un servicio de terceros (Google Analytics).
        <br />
        El Administrador utiliza cookies para almacenar parte de esta información en el dispositivo del Usuario.
        <br />
        En el siguiente enlace puede consultar los Términos de Uso de Google Analytics:
        http://www.google.com/analytics/terms/us.html.
        <br />
        Muchos de los servicios puestos a disposición permiten que los Usuarios compartan información entre sí. A su
        vez, el Usuario puede vincular su cuenta en el sistema de identificación único con sus perfiles en redes
        sociales.
      </span>

      <h3>Modificaciones</h3>
      <span>
        El Administrador se reserva el derecho de modificar en cualquier momento la presente Política de Privacidad,
        comprometiéndose a anunciarlo por medio de un aviso en el activo digital que corresponda.
      </span>

      <h3>Responsabilidad</h3>
      <span>
        El Administrador no controla ni es responsable por accionar de otros sitios web y otros productos y servicios a
        los cuales se puede acceder por medio de los Servicios Digitales, por lo que recomendamos la lectura de la
        Política de Privacidad de cada uno de ellos.
      </span>

      <h3>Derecho aplicable y Jurisdicción</h3>
      <span>
        En relación con esta Política de Privacidad rigen las disposiciones de la Ley Nº 25.326 de Protección de Datos
        Personales y sus normas complementarias y demás normativa de la República Argentina.
        <br />
        La Autoridad de Aplicación es la Dirección Nacional de Protección de Datos Personales, dependiente del
        Ministerio de Justicia y Derecho Humanos de la Nación.
        <br />
        En caso de surgir cualquier controversia respecto de la interpretación o cumplimiento de la presente, la misma
        será dirimida por los Tribunales en lo Contencioso Administrativo Federal con asiento en la Ciudad de Buenos
        Aires.
      </span>
    </div>
  );
};

import _ from 'lodash';
import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import CardContent from '../components/Card/Content';

export default ({ list }) => {
  return (
    <Container fluid>
      <Row>
        <Col xs="12">
          <Row>
            {_.map(list, (data, idx) => (
              <Col key={idx} md="12">
                <CardContent content={data} />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

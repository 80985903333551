// @Vendors
import _ from 'lodash';

import { logLevel } from './config';

const logLevels = { log: 0, info: 1, warn: 2, error: 3 };
const envLevel = logLevels[logLevel] || 0;

const mapError = args => {
  return _.map(args, arg => {
    return arg instanceof Error && arg.printStack ? arg.printStack() : arg;
  });
};

const print = (logName, ...args) => {
  const currentLevel = logLevels[logName] || 0;
  if (envLevel <= currentLevel) {
    // eslint-disable-next-line no-console
    console[logName](...mapError(args));
  }
};

export default {
  log: (...args) => {
    print('log', ...args);
  },

  info: (...args) => {
    print('info', ...args);
  },

  warn: (...args) => {
    print('warn', ...args);
  },

  error: (...args) => {
    print('error', ...args);
  }
};

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, HashRouter, Switch, Route, useHistory } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { navigateHandler } from './services';

import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import TermsPage from './pages/Terms';
import PoliticsPage from './pages/Politics';
import LandingPage from './pages/LandingPage';

import { StoreProvider } from './Store';
import { isMobile } from './config';
import './services/socket';
import './index.scss';

const Router = isMobile ? HashRouter : BrowserRouter;

const Root = () => {
  const history = useHistory();

  useEffect(() => {
    navigateHandler(({ pathname, state }) => {
      if (history.location.pathname !== pathname) {
        history.push({ pathname, state });
      }
    });
  }, [history]);

  return (
    <Switch>
      <Route path="/terminos-condiciones">
        <TermsPage />
      </Route>
      <Route path="/politicas-privacidad">
        <PoliticsPage />
      </Route>
      <Route path="/login">
        <LoginPage />
      </Route>
      <Route path="/register">
        <RegisterPage />
      </Route>
      <Route path="/reset-password">
        <ResetPasswordPage />
      </Route>
      <Route path="/change-password">
        <ChangePasswordPage />
      </Route>
      <Route path="/landing" exact>
        <LandingPage />
      </Route>
      <Route path="/" exact>
        <HomePage />
      </Route>
      <Route component={HomePage} />
    </Switch>
  );
};

const RootContainer = () => {
  return (
    <StoreProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Router>
        <Root />
      </Router>
    </StoreProvider>
  );
};

ReactDOM.render(<RootContainer />, document.getElementById('root'));

import io from 'socket.io-client';

import { apiServer, isMobile } from '../config';

import logger from '../logger';
import Event from './Event';
import { addNotification } from './index';

import PublishedAction from '../Store/Published';
import AuthActions from '../Store/Auth';

const local = {
  socket: undefined,
  ready: false,
  loggedIn: false,
  queue: []
};

const emit = (event, param, callback) => {
  if (local.ready) {
    logger.log(`socket:emit(${event})`);
    local.socket.emit(event, param, callback);
  } else {
    local.queue.push({ event, param, callback });
  }
};

const start = () => {
  local.loggedIn = true;
  local.socket = io(apiServer);
  initialize();
};

const end = () => {
  local.loggedIn = false;
  if (local.socket) local.socket.disconnect();
};

const initialize = () => {
  local.socket.on('connect', () => {
    local.ready = true;

    if (local.queue.length > 0) {
      local.queue.forEach(({ event, param, callback }) => {
        local.socket.emit(event, param, callback);
      });
      local.queue = [];
    }

    if (local.loggedIn) {
      const token = `${localStorage.getItem('token')}`;
      emit('subscribe', { token, isMobile }, () => {
        Event.emit('subscribe:success');
      });
    }
  });

  local.socket.on('published-sync', ({ status, statusType, data, destinations, _id, subStatus, child }) => {
    logger.log({ status, statusType, data, destinations, _id, subStatus, child });

    if (status === 'Published') PublishedAction.addUnread(data, _id, destinations);
    else if (status === 'Unpublished' || (status === 'Update' && subStatus === 'Unpublished'))
      PublishedAction.remPublished(data, destinations, _id, child);
    else if (status === 'Update') PublishedAction.update(statusType, data, _id, destinations);
  });

  local.socket.on('changeTag', ({ destination, contentList }) => {
    PublishedAction.changeTag(destination, contentList);
  });

  local.socket.on('logout', message => {
    AuthActions.logout();
    if (message) {
      addNotification({ message });
    }
  });
};

Event.on('socket:login', start);
Event.on('socket:logout', end);

Event.on('socket:disconnect', () => {
  if (local.socket) {
    local.socket.disconnect(true);
  }
});

export default {
  emit
};

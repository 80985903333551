import _ from 'lodash';
import React, { createContext, useReducer, useEffect } from 'react';

import logger from '../logger';

import { setDispatch, setState } from './Dispatch';

import * as Contents from './Published';
import * as Visibility from './Visibility';
import * as Auth from './Auth';

export const Store = createContext();

const initialState = {
  ...Contents.initialState,
  ...Visibility.initialState,
  ...Auth.initialState
};

const reducerList = [Contents.reducer, Visibility.reducer, Auth.reducer];

function reducer(originState, action) {
  logger.log(`dispatch(${action.type})`);

  return _.reduce(
    reducerList,
    (state, list) => {
      try {
        if (list[action.type]) {
          return list[action.type](state, action.payload);
        }
      } catch (err) {
        logger.error(err);
      }
      return state;
    },
    originState
  );
}

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    setDispatch(dispatch);
  }, []);

  useEffect(() => {
    setState(state);
  }, [state]);

  return <Store.Provider value={{ state, dispatch }}>{children}</Store.Provider>;
};

/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import _ from 'lodash';
import { FormContainer, FormGroup } from '../components/Form';
import { Validators, Validate } from '../utils/Validations';

import { navigate, addNotification } from '../services';
import CodeAction from '../Store/Code';

import './LoginRegisterPage.scss';

const labelsPassword = {
  newPassword: 'Contraseña nueva',
  repeatPassword: 'Repita la contraseña nueva'
};
const labelEmail = {
  email: 'Email'
};
export default () => {
  const [user, _setUser] = useState({ email: '', newPassword: '', repeatPassword: '' });

  const setUser = data => _setUser(prev => ({ ...prev, ...data }));
  const [errorPassword, setErrorPassword] = useState({});
  const [errorEmail, setErrorEmail] = useState({});

  const validatePasswords = async (_user, labels) => {
    const validation = {
      newPassword: Validators.password(),
      repeatPassword: Validators.sequential([Validators.password(), Validators.equals('newPassword')])
    };
    return Validate(_user, validation, labels);
  };

  const validateEmail = async (_user, labels) => {
    const validation = {
      email: Validators.email()
    };
    return Validate(_user, validation, labels);
  };

  const sendCodeResetPassword = async () => {
    const resultValidation = await validateEmail(user, labelEmail);
    if (_.isEmpty(resultValidation)) {
      CodeAction.createCodeResetPassword(user.email);
      setErrorEmail('');
      return;
    }
    addNotification({ message: 'Falta completar campos obligatorios' });
    setErrorEmail(resultValidation);
  };

  const changePassword = () => {
    return new Promise(async (resolve, reject) => {
      const resultValidation = await validatePasswords(user, labelsPassword);
      if (_.isEmpty(resultValidation)) {
        resolve();
      } else {
        addNotification({ message: 'Falta completar campos obligatorios' });
        setErrorPassword(resultValidation);
        reject();
      }
    });
  };
  const validateCodeResetPassword = () => {
    changePassword().then(() => {
      CodeAction.validateCodeResetPassword(user.email, user.code, user.newPassword).then(() => {
        navigate('/login');
      });
    });
  };

  return (
    <Container>
      <Row className="panel">
        <Col lg={{ span: 6, offset: 3 }}>
          <Row className="title">
            <Col xs="12">Olvidé mi Contraseña</Col>
            <Col xs="12">
              <label className="required">&nbsp;</label>
            </Col>
          </Row>

          <Row className="row-data">
            {/* <Col xs="12">
              <Form.Group controlId="formGroupEmail">
                <Form.Label>Email *</Form.Label>
                <Form.Control type="text" value={user.email} onChange={evt => setUser({ email: evt.target.value })} />
              </Form.Group>
            </Col> */}

            <Col xs="12">
              <FormContainer form={user} onChange={setUser} error={errorEmail} labels={labelEmail}>
                <FormGroup name="email" type="email" required />
              </FormContainer>
            </Col>

            <Col xs="12">
              <Row>
                <Col xs="12">
                  <Button variant="primary" type="button" onClick={sendCodeResetPassword} className="btn-code">
                    Enviar Codigo al Email
                  </Button>
                </Col>
              </Row>
            </Col>

            <Col xs="12">
              <Form.Group controlId="formGroupEmail">
                <Form.Label>Código de Email *</Form.Label>
                <br />
                <Form.Label>
                  <small>Recibirá por email el código a verificar</small>
                </Form.Label>
                <Form.Control type="number" value={user.code} onChange={evt => setUser({ code: evt.target.value })} />
              </Form.Group>
            </Col>
            <Col xs="12">
              <FormContainer form={user} onChange={setUser} error={errorPassword} labels={labelsPassword}>
                <FormGroup name="newPassword" type="password" required />
                <FormGroup name="repeatPassword" type="password" required />
              </FormContainer>
            </Col>

            <Col xs="12">
              <Row>
                <Col xs="12">
                  <Button variant="success" type="button" onClick={validateCodeResetPassword} className="btn-code">
                    Cambiar Contraseña
                  </Button>
                </Col>
              </Row>
            </Col>

            <Col xs="12">
              <Row>
                <Col xs="12">
                  <Button variant="secondary" type="button" onClick={() => window.history.back()} className="btn-code">
                    Volver
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

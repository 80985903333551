/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */

import _ from 'lodash';
import React, { useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

// import SwipeableTabs from 'react-swipeable-tabs';

import MdMenu from 'react-ionicons/lib/MdMenu';
import MdSearch from 'react-ionicons/lib/MdSearch';
import MdClose from 'react-ionicons/lib/MdClose';
import { visualName } from '../../config';

import { views } from '../../constants';

import Visibility from '../../Store/Visibility';

import './Header.scss';

const strEmpty = str => !str || str === '';

const ifEnterDo = action => evt => evt.keyCode === 13 && action();

export default ({ moreCounter = {}, fire, destinations, destinationIdx, openMenu }) => {
  const [view, setView] = useState(views.default);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (destinations && destinations.length > 0) {
      Visibility.changeDestinationVisible(0, destinations[0].title);
    }
  }, [destinations]);

  useEffect(() => {
    if (fire && destinations && destinationIdx !== undefined) {
      fire({ destination: { id: destinationIdx, name: destinations[destinationIdx].destination } });

      if (view !== views.default) {
        setSearch('');
        setView(views.default);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fire, destinationIdx, destinations]);

  useEffect(() => {
    if (fire && destinations && destinationIdx !== undefined) {
      fire({ view: { id: view, search, destination: destinations[destinationIdx].destination } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fire, view, destinations, destinationIdx]);

  const goSearch = () => {
    if (view === views.search) {
      fire({ view: { id: view, search, destination: destinations[destinationIdx].destination } });
    } else {
      setView(views.search);
    }
  };

  const closeSearch = () => {
    setView(views.default);
    setSearch('');
  };

  return (
    <Container className="app-header">
      <Row>
        <Col xs="12" className="header">
          <span className="app-name">
            {visualName.first}
            <span className="highlighted">{visualName.last}</span>
          </span>

          <img className="logo" src="./logo.png" alt="logo" />
        </Col>
      </Row>

      <Row>
        <Col xs="12" className="menu-search">
          <Button variant="link" onClick={openMenu}>
            <MdMenu color="#fff" fontSize="2rem" />
          </Button>

          <div className={`search${destinationIdx !== 0 ? ' disabled' : ''}`}>
            <Form.Control
              type="text"
              value={search}
              disabled={destinationIdx !== 0}
              onChange={evt => setSearch(evt.target.value)}
              onKeyDown={ifEnterDo(goSearch)}
            />

            {view === views.search && (
              <Button onClick={closeSearch} variant="link">
                <MdClose color="#fff" />
              </Button>
            )}

            <Button disabled={strEmpty(search) || destinationIdx !== 0} onClick={goSearch} variant="link">
              <MdSearch color="#fff" />
            </Button>
          </div>
        </Col>
      </Row>

      {destinations && destinations.length > 0 && (
        <Row>
          <Col xs="12" className="destinations">
            <div className="tab-container">
              {_.map(destinations, ({ title, destination }, idx) => (
                <span
                  key={idx}
                  className={`tab-header-title ${destinationIdx === idx ? 'active' : ''}`}
                  onClick={() => Visibility.changeDestinationVisible(idx, title)}
                >
                  {title} {moreCounter[destination] > 0 && destinationIdx !== idx && <label className="has-unread" />}
                </span>
              ))}
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
};

import _ from 'lodash';

import logger from '../logger';

const shareContentWeb = async content => {
  const tags = _.map(content.tags, tag => tag.toUpperCase());
  logger.log(content.text);
  const summary = (content.summary || '')
    .replaceAll('<p><br></p>', '%0D%0A')
    .replaceAll('<p>', '%0D%0A')
    .replaceAll('<br>', '%0D%0A')
    .replaceAll('<li>', '%0D%0A')
    .replace(/<[^>]*>/g, '')
    .trim();
  const text = (content.text || '')
    .replaceAll('<p><br></p>', '%0D%0A')
    .replaceAll('<p>', '%0D%0A')
    .replaceAll('<br>', '%0D%0A')
    .replaceAll('<li>', '%0D%0A')
    .replace(/<[^>]*>/g, '')
    .trim();

  const message = `📌 *${tags.join(' / ')}*
  ${content.medium ? `%0D%0A*${content.medium.name}` : ''}* - *${content.title}*
  ${summary ? `%0D%0A${summary}` : ''}
  ${text ? `%0D%0A${text}` : ''}
  ${content.sourceLink ? `%0D%0A${content.sourceLink}` : ''}
  `;

  window.open(`https://api.whatsapp.com/send?text=${message.replaceAll('\n', '')}`);
};

export default {
  shareContentWeb
};

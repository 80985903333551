/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */

import _ from 'lodash';
import React, { useState, useContext, useCallback } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { navigate, addNotification } from '../services';
import Auth from '../Store/Auth';
import { Store } from '../Store';

import { FormContainer, FormGroup } from '../components/Form';
import { Validators, Validate } from '../utils/Validations';

import './LoginRegisterPage.scss';

const labels = {
  oldPassword: 'Contraseña actual',
  newPassword: 'Contraseña nueva',
  repeatPassword: 'Repita la contraseña nueva'
};

export default () => {
  const [usr, _setUsr] = useState({ oldPassword: '', newPassword: '', repeatPassword: '' });
  const { state } = useContext(Store);

  const [error, setError] = useState({});
  const [msgError, setMsgError] = useState({});

  const setUsr = useCallback((data = {}) => {
    setError({});
    setMsgError({});
    _setUsr(prev => ({ ...prev, ...data }));
  }, []);

  const validatePasswords = async (user, _labels) => {
    const validation = {
      oldPassword: Validators.required(),
      newPassword: Validators.password(),
      repeatPassword: Validators.sequential([Validators.password(), Validators.equals('newPassword')])
    };
    return Validate(user, validation, _labels);
  };

  const changePassword = async () => {
    const resultValidation = await validatePasswords(usr, labels);
    if (_.isEmpty(resultValidation)) {
      Auth.changePassword(state.user._id, usr.newPassword, usr.oldPassword).then(() => {
        navigate('/');
      });
    } else {
      addNotification({ message: 'Falta completar campos obligatorios' });
      setError(resultValidation);
    }
  };
  const handleBackButton = async () => {
    const { firstSession } = state.user;
    if (!firstSession) {
      window.history.back();
      return;
    }
    Auth.logout();
  };
  return (
    <Container>
      <Row className="panel">
        <Col lg={{ span: 6, offset: 3 }}>
          <Row className="title">
            <Col xs="12">Cambiar Contraseña</Col>
            <Col xs="12">
              <label className="required">* Obligatorios</label>
            </Col>
          </Row>

          <Row className="row-data">
            <Col xs="12">
              <FormContainer form={usr} onChange={setUsr} error={error} labels={labels}>
                <FormGroup name="oldPassword" type="password" required />
                <FormGroup name="newPassword" type="password" required />
                <FormGroup name="repeatPassword" type="password" required />
              </FormContainer>
            </Col>
          </Row>

          {msgError && (
            <Row>
              <Col xs="12">
                <Form.Label>{msgError.error}</Form.Label>
              </Col>
            </Row>
          )}

          <Col xs="12">
            <Row>
              <Col xs="12">
                <Button variant="success" type="button" onClick={changePassword} className="btn-code">
                  Cambiar Contraseña
                </Button>
              </Col>
            </Row>
          </Col>

          <Col xs="12">
            <Row>
              <Col xs="12">
                <Button variant="secondary" type="button" onClick={handleBackButton} className="btn-code">
                  Volver
                </Button>
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

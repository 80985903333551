import _ from 'lodash';

const isEmpty = value => {
  return value === undefined || value === null || value === '' || (_.isArray(value) && value.length === 0);
};

const get = (form, field) => {
  return form ? form[field] : undefined;
};

const returnError = (label, labelDefault, props) => {
  if (!label) return labelDefault;

  return _.isFunction(label) ? label(props) : label;
};

export const Validators = {
  required: label => async (value, form, name, labels) => {
    if (isEmpty(value)) {
      return returnError(label, `${labels[name]} es requerido`, { value, form, name, labels });
    }

    return undefined;
  },

  equals: (fieldCompare, label) => async (value, form, name, labels) => {
    const valueCompare = get(form, fieldCompare);

    if (value !== valueCompare) {
      return returnError(label, `Debe ser igual que ${labels[fieldCompare]}`, { value, form, name, labels });
    }

    return undefined;
  },

  parallel: list => async (value, form, name, labels) =>
    Promise.all(_.map(list, func => Promise.resolve(func(value, form, name, labels)))),

  sequential: list => async (value, form, name, labels) => {
    const execSequentially = (promise, func) => promise.then(error => error || func(value, form, name, labels));
    return _.reduce(list, execSequentially, Promise.resolve());
  },

  password: label => async (value, form, name, labels) => {
    if (!/^(?=.*[\u0021-\u002b\u003c-\u0040])\S{6,16}$/.test(value)) {
      return returnError(
        label,
        `${labels[name]}. Debe estar compuesta por 6 caracteres como mínimo y debe contener al menos un caracter especial.(Ejemplos de caracteres especiales: *"#$%&()=?)`,
        { value, form, name, labels }
      );
    }
    return undefined;
  },

  username: label => async (value, form, name, labels) => {
    if (!value || value.length < 6) {
      return returnError(label, `${labels[name]}. Debe estar compuesta por 6 caracteres como mínimo`, {
        value,
        form,
        name,
        labels
      });
    }
    if (!value || /\s/.test(value)) {
      return returnError(label, `${labels[name]}. No puede contener espacios vacios`, { value, form, name, labels });
    }
    return undefined;
  },
  email: label => async (value, form, name, labels) => {
    const exp = /\S+@\S+\.\S+/;

    if (!exp.test(value)) {
      return returnError(label, `${labels[name]}. Debe tener el formato ejemplo@ejemplo.com`, {
        value,
        form,
        name,
        labels
      });
    }
    return undefined;
  },
  phone: label => async (value, form, name, labels) => {
    const expLetter = /[a-zA-Z ]/g;
    const expCharEspecial = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/;

    if ((value && value.length < 10) || expLetter.test(value) || expCharEspecial.test(value)) {
      return returnError(
        label,
        `${labels[name]}. Debe estar compuesto por 10 digitos y el formato es [código de area] + [número de telefono (sin prefijo 15)]. Ej:11 1234-1234`,
        {
          value,
          form,
          name,
          labels
        }
      );
    }
    return undefined;
  }
};

const process = async ({ validations, name, value }, form, labels) => {
  const listFn = _.isFunction(validations) ? [validations] : _.isArray(validations) ? validations : [];

  return Promise.all(_.map(listFn, validation => validation(value, form, name, labels)));
};

export const Validate = async (form, validationsList, labels = {}) => {
  // Unifying the input objects to improve the validation process.
  const formValidation = _.map(validationsList, (validations, name) => ({
    name,
    value: get(form, name),
    validations
  }));

  const processed = await Promise.all(
    _.flattenDeep(
      _.map(formValidation, async fieldData => {
        const errors = _.compact(await process(fieldData, form, labels));
        return { name: fieldData.name, errors: errors && errors.length > 0 ? errors : undefined };
      })
    )
  );

  return _.reduce(
    processed,
    (result, { name, errors }) => {
      if (errors) {
        result[name] = errors;
      }

      return result;
    },
    {}
  );
};

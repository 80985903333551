export const logLevel = process.env.LOG_LEVEL || 'log';

export const isMobile = process.env.REACT_APP_IS_MOBILE === 'true';
export const env = process.env.REACT_APP_ENV;

export const download = {
  linkIOS: process.env.REACT_APP_LINK_DOWNLOAD_IOS,
  linkAndroid: process.env.REACT_APP_LINK_DOWNLOAD_ANDROID
};

export const apiServer = process.env.REACT_APP_SERVER;

export const appName = process.env.REACT_APP_NAME;

export const appEmail = process.env.REACT_APP_EMAIL;

export const visualName = {
  first: process.env.REACT_APP_NAME_FIRST,
  last: process.env.REACT_APP_NAME_LAST
};

/* eslint-disable react/button-has-type */
import React, { useEffect, useContext } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import _ from 'lodash';
import ReactMarkdown from 'react-markdown';
import { HashLink as Link } from 'react-router-hash-link';
import './LandingPage.scss';
import WarningOutline from 'react-ionicons/lib/IosWarningOutline';

import { appName, download } from '../config';
import { navigate } from '../services';

import Auth from '../Store/Auth';
import { Store } from '../Store';

const BASE_PATH_ASSETS = `${window.location.origin}/assets`;

const InstallationText = () => {
  const text = `${appName} App es una aplicación desarrollada en conjunto entre Secretaría de Prensa y 
  Comunicación Pública y Ministerio de Desarrollo Productivo que permite tener 
  un rápido acceso a todas las noticias de interés para funcionarios del gobierno nacional.   

  La aplicación está optimizada tanto para Android como para IOS, y también se la puede visualizar en un navegador web de PC.
  Y está disponible para todos los funcionarios de la administración pública.   
  
  
  Se puede descargar e instalar en su dispositivo con los links que están disponibles más abajo.   

  Y una vez instalada solicitará que el usuario se registre. Tras haber realizado ese trámite, se obtiene la autorización de uso.   
    
  `;

  return <ReactMarkdown>{text}</ReactMarkdown>;
};
const IOSText = () => {
  const text = `En el caso de iOS una vez instalada la App es requerido habiltarla desde Configuracion > General > Adm. de dispositivos > Subsecretaría de Gobierno Digital > Confiar en "Subsecretaría de Gobierno Digital"   `;

  return <ReactMarkdown>{text}</ReactMarkdown>;
};

const ListCard = props => {
  const { cards } = props;
  const items = _.map(cards, (item, key) => {
    return (
      <li key={key} className="card">
        <div className="spacing" />
        <img alt="tarjeta ventajas" className="img" src={item.img} />
        <span className="title">
          <ReactMarkdown>{item.publicName}</ReactMarkdown>
        </span>
      </li>
    );
  });
  return <ul className="list-card">{items}</ul>;
};
const FirstPage = () => {
  const { dispatch } = useContext(Store);
  useEffect(() => {
    Auth.syncLoggedIn(dispatch);
  }, [dispatch]);

  return (
    <nav className="first-page">
      <Row className="header">
        <div className="logo-container">
          <img alt="logo app" className="logo" src={`${BASE_PATH_ASSETS}/img/Logo.png`} />
        </div>
        <div className="login-container">
          <button type="button" className="btn" onClick={() => navigate('/login')}>
            <div className="login-container">
              <span className="go-login">
                Si ya estas registrado, <span className="link">podés acceder a la versión web aqui</span>
              </span>
            </div>
          </button>
        </div>
      </Row>
      <Row className="section">
        <div>
          <img alt="logo app" className="top" src={`${BASE_PATH_ASSETS}/img/logo_app.png`} />
        </div>
        <div>
          <span className="center">
            Aplicación para el acceso al sistema de monitoreo de noticias del Gobierno nacional
          </span>
        </div>
        <div>
          <Link to="#download-app" className="btn-img bottom">
            <img alt="boton de descarga" className="logo" src={`${BASE_PATH_ASSETS}/img/BotonDescargarInstalar.png`} />
          </Link>
        </div>
      </Row>
    </nav>
  );
};
const LastPage = () => {
  const { state } = useContext(Store);

  const handleIOS = () => {
    window.location.href = state?.version?.linkIOS || download.linkIOS;
  };

  const handleAndroid = () => {
    window.location.href = state?.version?.linkAndroid || download.linkAndroid;
  };

  return (
    <div id="download-app" className="last-page">
      <div className="section">
        <div className="title-container">
          <h3 className="title"> Links de descarga</h3>
        </div>
        <div className="download-container">
          <div className="android">
            <button className="button-image" onClick={handleAndroid}>
              <img alt="descargar en android" src={`${BASE_PATH_ASSETS}/img/LinkDescargaAndroid.png`} />
            </button>
          </div>
          <div className="ios">
            <button className="button-image" onClick={handleIOS}>
              <img alt="descargar en ios" src={`${BASE_PATH_ASSETS}/img/LinkDescargaIOS.svg`} />
            </button>
          </div>
        </div>
        <div className="ios-text-container">
          <div className="text">
            <WarningOutline color="#fff" fontSize="65px" />
            <IOSText />
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="left">
          <img alt="Argentina Unida" src={`${BASE_PATH_ASSETS}/img/LogoArgentinaUnida.png`} />
        </div>
        <div className="right">
          <img alt="Escudo nacional argentino" src={`${BASE_PATH_ASSETS}/img/EscudoFooter.png`} />
        </div>
      </div>
    </div>
  );
};
const AdvantagePage = () => {
  const cards = [
    {
      publicName: `ALERTAS EN  
      TIEMPO REAL`,
      img: `${BASE_PATH_ASSETS}/img/iconoAlarmas.png`
    },
    {
      publicName: `ACCESO A LA  
      SINTESIS DE PRENSA`,
      img: `${BASE_PATH_ASSETS}/img/iconoSintesis.png`
    },
    {
      publicName: `DISPONIBLE PARA  
      TODOS LOS SISTEMAS`,
      img: `${BASE_PATH_ASSETS}/img/iconoDisponible.png`
    },
    {
      publicName: `AGIL, SENCILLA   
      Y ROBUSTA`,
      img: `${BASE_PATH_ASSETS}/img/iconoAgil.png`
    }
  ];

  return (
    <div className="section">
      <div className="title-container">
        <h3 className="title"> Ventajas</h3>
      </div>
      <ListCard cards={cards} />
      <div className="spacing" />
    </div>
  );
};
const InstallationPage = () => {
  return (
    <div className="section">
      <div className="title-container">
        <h3 className="title"> Instalación</h3>
      </div>
      <div className="install-container">
        <div className="text">
          {' '}
          <InstallationText />
        </div>
        <div className="img-container">
          <img
            alt="Muestra de app en smartphone"
            className="img"
            src={`${BASE_PATH_ASSETS}/img/CelularInstalacion.svg`}
          />
        </div>
      </div>
      <div className="spacing" />
    </div>
  );
};
export default () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <Container fluid className="landing-container">
      <FirstPage />
      <AdvantagePage />
      <InstallationPage />
      <LastPage />
    </Container>
  );
};

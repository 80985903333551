import _ from 'lodash';
import React, { useState } from 'react';
import moment from 'moment';
import 'moment/locale/es';

import './Card.scss';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import IosArrowDown from 'react-ionicons/lib/IosArrowDown';
import IosArrowUp from 'react-ionicons/lib/IosArrowUp';
import IosLink from 'react-ionicons/lib/IosLink';
import IosDocumentOutline from 'react-ionicons/lib/IosDocumentOutline';
import LogoWhatsapp from 'react-ionicons/lib/LogoWhatsapp';

import Share from '../../services/Share';

const isEmpty = text => text === undefined || text === null || text === '' || text === '<p></p>↵' || text.length < 9;

const ButtonsText = ({ content, visible, setVisible, setLoading, textIsEmpty }) => {
  return (
    <>
      <div className="buttons-control">
        {!textIsEmpty && (
          <Button className="view-more link" variant="outline-primary" onClick={() => setVisible(!visible)}>
            {visible ? <IosArrowUp color="white" /> : <IosArrowDown color="white" />}
          </Button>
        )}
        {content.sourceLink && (
          <Button className="link" variant="outline-secondary" href={content.sourceLink} target="_blank">
            <IosLink color="white" />
          </Button>
        )}
        {content.attachFileName && (
          <Button className="link" variant="outline-secondary" href={content.attachFileName} target="_blank">
            <IosDocumentOutline color="white" />
          </Button>
        )}

        <Button
          className="link"
          variant="outline-secondary"
          onClick={async () => {
            setLoading(true);
            await Share.shareContentWeb(content);
            setLoading(false);
          }}
        >
          <LogoWhatsapp color="white" />
        </Button>
      </div>
    </>
  );
};

const CardContent = ({ content, className = '', isChild }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const textIsEmpty = isEmpty(content.text);
  const hasImage = content.imageFileName;

  return (
    <>
      <Card className={`${className}${content.children && content.children.length > 0 ? ' parent' : ''}`}>
        <div className={visible ? 'card-wrapper full' : 'card-wrapper'}>
          <Row className="card-wrapper__block">
            {hasImage && (
              <Col xs="12" lg="4" className="image-wrapper">
                <div className="img-container">
                  <Card.Img variant="top" src={content.imageFileName} />
                </div>
              </Col>
            )}

            <Col xs="12" lg={hasImage ? '8' : '12'}>
              <Card.Body>
                <Row className="card-wrapper__content">
                  <div className="content-wrapper">
                    <div className="title-header">
                      {content.medium && content.medium.image && (
                        <div className="title-header__image">
                          <img src={content.medium.image} alt="logo" />
                        </div>
                      )}
                      {content.tags && content.tags.length > 0 && (
                        <div className="tags">
                          <span>{content.tags.join(' / ')}</span>
                        </div>
                      )}
                      {content.title && (
                        <Card.Title className="title">
                          {content.title}
                          {content.medium && content.medium.name ? ` - ${content.medium.name}` : ''}
                          {` - ${moment(content.dateTime).format('DD/MM/YY HH:mm')}`}
                        </Card.Title>
                      )}

                      {content.summary && (
                        <Card.Text
                          className="summary full"
                          dangerouslySetInnerHTML={{
                            __html: content.summary
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <ButtonsText {...{ content, isChild, visible, loading, setVisible, setLoading, textIsEmpty }} />
                </Row>
              </Card.Body>
            </Col>
          </Row>
        </div>

        {visible && !textIsEmpty && (
          <div className="card-text-full">
            <div>
              <Card.Text
                className="complete-text"
                dangerouslySetInnerHTML={{
                  __html: content.text
                }}
              />
            </div>
          </div>
        )}
      </Card>
      {_.map(content.children, (dataChild, key) => (
        <CardContent
          key={key}
          content={dataChild}
          className={`child${content.children.length - 1 === key ? ' last' : ''}`}
        />
      ))}
    </>
  );
};

export default CardContent;

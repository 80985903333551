import * as Api from './Api';

import ContentDestination from '../components/destinations/ContentDestination';
// import InformDestination from '../components/destinations/InformDestination';
import SynthesisDestination from '../components/destinations/SynthesisDestination';

import { views } from '../constants';

const tabs = [
  { title: 'Alertas', Component: ContentDestination, destination: 'Alertas' },
  // { title: 'Resúmenes', Component: SynthesisDestination, destination: 'Informes' },
  // { title: 'Compilados', Component: SynthesisDestination, destination: 'Compilados' },
  // { title: 'Desgrabaciones', Component: SynthesisDestination, destination: 'Desgrabaciones' },
  { title: 'Síntesis de Prensa', Component: SynthesisDestination, destination: 'Síntesis de Prensa' }
];

export const getDestinations = async () => {
  return tabs;
};

export const processSearchView = async ({ view: { id, search } } = {}) => {
  if (!id || id !== views.search) return [];

  const { list } = await Api.findAll('contents', {
    search,
    pagination: false,
    filter: { status: 'Publicado' }
  });

  return list;
};

import EventEmitter from 'eventemitter3';

import logger from '../logger';

const cache = {};
const EE = new EventEmitter();

const emit = (event, ...data) => {
  logger.log(`Event.emit(${event})`);
  cache[event] = data;
  EE.emit(event, ...data);
};

const on = (event, callback) => {
  EE.on(event, callback);

  if (cache[event]) {
    callback(...cache[event]);
  }
};

const once = (event, callback) => {
  if (cache[event]) {
    callback(...cache[event]);
  } else {
    EE.once(event, callback);
  }
};

export default {
  emit,
  once,
  on,
  off: EE.off
};

/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */

import _ from 'lodash';
import React, { useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import MdRefresh from 'react-ionicons/lib/MdRefresh';

import CardContent from '../Card/Content';
import LoadMore from '../visibility/LoadMore';
import PublishedActions from '../../Store/Published';

import './Destinations.scss';

export default ({ destination, list, onLoadMore, moreCounter }) => {
  const [hasLoadMore, setHasLoadMore] = useState(true);
  const [msgContents, setMsgContents] = useState();

  const loadMore = async () => {
    if (list === undefined) return;
    if (list.length > 0) {
      const row = _.last(list);
      const hasMore = await onLoadMore(destination, row._id);
      setHasLoadMore(hasMore);
    } else {
      setHasLoadMore(false);
    }
  };

  const loadNewContent = async () => {
    PublishedActions.getUnreadList(destination);
  };

  useEffect(() => {
    if (moreCounter) {
      if (moreCounter > 1) {
        setMsgContents(`Hay ${moreCounter} contenidos nuevos`);
      } else {
        setMsgContents(`Hay ${moreCounter} contenido nuevo`);
      }
    }
  }, [moreCounter]);

  return (
    <>
      {moreCounter > 0 && (
        <div className="new-content" onClick={loadNewContent}>
          <div>
            <span>
              {msgContents} <MdRefresh color="white" beat />
            </span>
          </div>
        </div>
      )}

      <Container fluid>
        <Row>
          {_.map(list, ({ data, _id }, idx) => (
            <Col key={idx} md="12">
              <CardContent destination={destination} content={data} id={_id} />
            </Col>
          ))}

          {hasLoadMore && (
            <Col xs="12">
              <LoadMore onLoadMore={loadMore}>
                <>
                  <label className="load-more">
                    <p className="load-more__text">Cargar mas</p>
                  </label>
                </>
              </LoadMore>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

import AuthAction from '../Store/Auth';

import { Validators, Validate } from '../utils/Validations';

const ValidateExisting = result => fieldResult => async (value, form, name, labels) => {
  if (result && result[fieldResult]) {
    return `${labels[name]} existente`;
  }

  return undefined;
};

export const validateRegister = async (user, labels) => {
  const validateExisting = ValidateExisting(await AuthAction.registerVerify(user));

  const validation = {
    firstName: Validators.required(),
    lastName: Validators.required(),
    username: Validators.sequential([
      Validators.required(),
      Validators.username(),
      validateExisting('hasUserWithUsername')
    ]),
    password: Validators.password(),
    passwordRepeat: Validators.sequential([Validators.password(), Validators.equals('password')]),
    email: Validators.sequential([Validators.required(), Validators.email(), validateExisting('hasUserWithEmail')]),
    phone: Validators.sequential([Validators.required(), Validators.phone(), validateExisting('hasUserWithPhone')])
  };

  return Validate(user, validation, labels);
};

export const validateLogin = async (credential, labels) => {
  const validation = {
    username: Validators.required(),
    password: Validators.required()
  };

  return Validate(credential, validation, labels);
};

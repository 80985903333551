import React from 'react';
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { visualName } from '../../config';

import AuthActions from '../../Store/Auth';

import Package from '../../../package.json';

import './Menu.scss';

export default ({ user = {} }) => {
  return (
    <Container fluid className="menu-container">
      <Row className="menu-container-item-1">
        <Col xs="12" className="text-right mobile">
          <img className="logo" src="./logo.png" alt="logo" />
        </Col>
      </Row>
      <Row className="menu-container-item-2">
        <Col xs="12" className="text-left mobile">
          <span className="app-name">
            Síntesis
            <span className="highlighted">{visualName.last}</span>
          </span>
        </Col>
      </Row>
      <Row className="menu-container-item-3">
        <Col xs="12" className="txt-center username-container">
          <h3 className="firstname">{user.firstName}</h3>
          <h3 className="lastname">{user.lastName}</h3>
        </Col>
        <Col xs="12" className="txt-center btn-container">
          <Link to="/change-password">
            <Button variant="outline-secondary" className="btn-width font">
              Cambiar contraseña
            </Button>
          </Link>
        </Col>
        <Col xs="12" className="txt-center btn-container">
          <Link to="/terminos-condiciones">
            <Button variant="outline-secondary" className="font">
              Términos y Condiciones
            </Button>
          </Link>
        </Col>
        <Col xs="12" className="txt-center btn-container">
          <Link to="/politicas-privacidad">
            <Button variant="outline-secondary" className="btn-width font">
              Políticas de Privacidad
            </Button>
          </Link>
        </Col>

        <Col xs="12" className="txt-center version-container">
          <small>v{Package.version}</small>
        </Col>

        <Col xs="12" className="txt-center btn-salir-container">
          <Button onClick={() => AuthActions.logout()} className="btn-width ">
            <h5 className="txt-exit">SALIR</h5>
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

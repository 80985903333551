/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { navigate } from '../services';
import AuthAction from '../Store/Auth';

import { FormContainer, FormContainerCell, FormGroup } from '../components/Form';
import { validateRegister } from './LoginRegisterValidations';
import CodeVerificationPage from './CodeVerificationPage';

import './LoginRegisterPage.scss';

const labels = {
  firstName: 'Nombre',
  lastName: 'Apellido',
  username: 'Usuario',
  password: 'Contraseña',
  passwordRepeat: 'Repetir Contraseña',
  boss: 'Autoridad Superior',
  email: 'Email',
  phone: 'Teléfono: (Cod. área sin el cero + número) sin espacios'
};

const legends = {
  phone: 'Ej.: Real (011) 15-2233-4455 --> Escribir 1122334455'
};

export default () => {
  const [user, _setUser] = useState({});
  const [error, setError] = useState({});
  const [userToVerify, setUserToVerify] = useState();

  const setUser = useCallback((data = {}) => {
    setError({});
    _setUser(prev => ({ ...prev, ...data }));
  }, []);

  useEffect(() => {
    AuthAction.syncLoggedIn({ stayPage: true });
  }, []);

  const goRegister = async () => {
    const resultValidation = await validateRegister(user, labels);
    if (_.isEmpty(resultValidation)) {
      const { firstName, lastName, username, password, boss, email, phone } = user;
      const res = await AuthAction.register({ firstName, lastName, username, password, boss, email, phone });
      setError(res);
      if (res.data) {
        setUserToVerify(res.data);
      }
    } else {
      setError(resultValidation);
    }
  };

  if (userToVerify) {
    return <CodeVerificationPage usr={userToVerify} onBack={() => navigate('/login')} />;
  }

  return (
    <Container>
      <Row className="panel">
        <Col lg={{ span: 6, offset: 3 }}>
          <Row className="title">
            <Col xs="12">Ingrese sus datos</Col>
            <Col xs="12">
              <label className="required">* Obligatorios</label>
            </Col>
          </Row>

          <Row className="row-data">
            <Col xs="12">
              <FormContainer form={user} onChange={setUser} error={error} labels={labels} legends={legends}>
                <FormGroup name="firstName" required />
                <FormGroup name="lastName" required />
                <FormGroup name="username" required noblanks />
                <FormContainerCell cell="6">
                  <FormGroup name="password" type="password" required />
                  <FormGroup name="passwordRepeat" type="password" required />
                </FormContainerCell>
                <FormGroup name="boss" />
                <FormGroup name="email" placeholder="ejemplo@ejemplo.com" required />
                <FormGroup
                  name="phone"
                  placeholder="[Cód.Area + Número de teléfono (sin prefijo 15)] 11 1234-1234"
                  required
                  numeric
                />
              </FormContainer>
            </Col>
          </Row>

          <Row>
            <Col xs="12 button-panel">
              <Button
                variant="secondary"
                type="button"
                onClick={() => {
                  navigate('/login');
                }}
              >
                Ya estoy registrado
              </Button>

              <Button variant="primary" type="button" onClick={goRegister}>
                Registrarme
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

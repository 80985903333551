import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import 'moment/locale/es';

import './Card.scss';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import IosLink from 'react-ionicons/lib/IosLink';
import IosDocumentOutline from 'react-ionicons/lib/IosDocumentOutline';

const ContentText = ({ content }) => {
  return (
    <>
      <Row className="buttons-control">
        <Col xs="12">
          {content.sourceLink && (
            <Button className="link" variant="outline-secondary" href={content.sourceLink} target="_blank">
              <IosLink color="white" />
            </Button>
          )}

          {content.attachFileName && (
            <Button className="link" variant="outline-secondary" href={content.attachFileName} target="_blank">
              <IosDocumentOutline color="white" />
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

const destinationLabel = {
  Compilados: 'Compilado',
  Desgrabaciones: 'Desgrabación',
  'Síntesis de Prensa': 'Destacadas'
};

const CardComponent = ({ content, className = '' }) => {
  const hasImage = content.imageFileName;
  /** FIX: revisar valicacion para mostrar el pretitulo de las tarjetas */
  const hasPretitle = content.destinations !== undefined;

  return (
    <>
      <Card className={`synthesis ${className}${content.children && content.children.length > 0 ? ' parent' : ''}`}>
        <div className="card-wrapper">
          <Row className="card-wrapper__block">
            {hasImage && (
              <Col xs="12" lg="4" className="image-wrapper">
                <div className="img-container">
                  <Card.Img variant="top" src={content.imageFileName} />
                </div>
              </Col>
            )}

            <Col xs="12" lg={hasImage ? '8' : '12'}>
              <Card.Body>
                <Row classNam="card-wrapper__content">
                  <div className="content-wrapper">
                    <div className="title-header">
                      <Row className="details">
                        {hasPretitle && (
                          <Col xs="6" className="pre-title">
                            {destinationLabel[content.destinations[0]]}
                          </Col>
                        )}

                        <Col xs={hasPretitle ? '6' : '12'} className="date">
                          {moment(content.dateTime).format('DD [de] MMMM', 'es')}
                        </Col>
                      </Row>

                      <Card.Title className="title">{content.title}</Card.Title>

                      {content.summary && (
                        <Card.Text
                          className="summary"
                          dangerouslySetInnerHTML={{
                            __html: content.summary
                          }}
                        />
                      )}
                    </div>
                  </div>
                </Row>

                <ContentText content={content} />
              </Card.Body>
            </Col>
          </Row>
        </div>
      </Card>

      {_.map(content.children, (dataChild, key) => (
        <CardComponent
          key={key}
          content={dataChild}
          className={`child${content.children.length - 1 === key ? ' last' : ''}`}
        />
      ))}
    </>
  );
};

export default CardComponent;

import axios from 'axios';
import _ from 'lodash';

import { apiServer } from '../config';

import { addNotification, navigate } from './index';

function ApiException(statusCode = 404, error) {
  this.statusCode = statusCode;
  this.message = error.message;
  this.origin = error;
  this.business = _.get(error, 'response.data.message');
}

const goTo = (stayPage, page = '/landing') => {
  if (!stayPage) {
    navigate(page);
  }
};

const privateREST = async (verb, endpoint, params, options = {}) => {
  const headers = options.headers || {};
  headers.Authorization = localStorage.getItem('token');
  if (!headers.Authorization) {
    goTo(options.stayPage);
    throw new Error('UnauthorizedException');
  }
  const _axios = axios.create({ headers });

  try {
    const res = await _axios[verb](`${apiServer}/${endpoint}`, params);
    return res.data;
  } catch (err) {
    const statusCode = _.get(err, 'response.data.statusCode');
    if (statusCode === 401) {
      // hit api/unsubscribe/{localStorage.devicePNToken}

      goTo(options.stayPage);
      localStorage.clear();
    }
    if (statusCode === 403 && !options.stayPage) {
      if (!options.silence) addNotification({ message: 'Acceso No Permitido' });
      goTo(options.stayPage, '/');
    }
    throw new ApiException(statusCode, err);
  }
};

const privateGet = (endpoint, options = {}) => privateREST('get', endpoint, undefined, options);
const privatePost = (endpoint, params, options = {}) => privateREST('post', endpoint, params, options);
const privatePut = (endpoint, params, options = {}) => privateREST('put', endpoint, params, options);
const privateDelete = (endpoint, options = {}) => privateREST('delete', endpoint, undefined, options);

export const publicPost = async (endpoint, body = {}) => {
  try {
    const res = await axios.post(`${apiServer}/${endpoint}`, body);
    return res.data;
  } catch (err) {
    const statusCode = _.get(err, 'response.data.statusCode');
    throw new ApiException(statusCode, err);
  }
};

const addQueryParams = (endpoint, params = {}) => {
  return Object.keys(params)
    .reduce(
      (ant, paramKey) =>
        !!params[paramKey] && params[paramKey] !== ''
          ? _.isObject(params[paramKey])
            ? `${ant}${paramKey}=${JSON.stringify(params[paramKey])}&`
            : `${ant}${paramKey}=${params[paramKey]}&`
          : ant,
      `${endpoint}?`
    )
    .slice(0, -1);
};

export const login = async credential => {
  const result = await axios.post(`${apiServer}/auth/login`, credential);
  return result.data;
};

export const loginSync = ({ stayPage, silence } = {}) => privateGet('auth/sync', { stayPage, silence });

export const syncVersion = async () => {
  const result = await axios.get(`${apiServer}/v1/versions/actual`);
  return result.data;
};

export const register = async user => {
  const result = await axios.post(`${apiServer}/auth/register`, user);
  return result.data;
};

export const registerVerify = async data => {
  const result = await axios.post(`${apiServer}/auth/register/verify`, data);
  return result && result.data ? result.data : {};
};

// GENERICS
export const findAll = (resource, query = {}, { version = 'v1' } = {}) =>
  privateGet(addQueryParams(`${version}/${resource}`, query));
export const getOne = (resource, id, { version = 'v1' } = {}) => privateGet(`${version}/${resource}/${id}`);
export const create = (resource, params, { version = 'v1' } = {}) => privatePost(`${version}/${resource}`, params);
export const update = (resource, id, params, { version = 'v1' } = {}) =>
  privatePut(`${version}/${resource}/${id}`, params);
export const remove = (resource, id, { version = 'v1' } = {}) => privateDelete(`${version}/${resource}/${id}`);
export const uploadFile = (file, { version = 'v1' } = {}) => {
  const formData = new FormData();
  formData.append('file', file);

  return privatePost(`${version}/file`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
};

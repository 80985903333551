import _ from 'lodash';
import React, { useContext, useState, useCallback, useEffect } from 'react';

import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';

import Container from 'react-bootstrap/Container';

import { ModalFooter } from 'react-bootstrap';
import AppContainer from '../components/visibility/AppContainer';

import { Store } from '../Store';
import PublishedActions from '../Store/Published';
import AuthAction from '../Store/Auth';

import MenuPanel from '../panel/MenuPanel/Menu';
import HeaderPanel from '../panel/HeaderPanel/Header';
import SearchPanel from '../panel/SearchPanel';

import { useAction } from '../utils/Hooks';
import { views } from '../constants';

import { getDestinations, processSearchView } from '../services/view';

import './HomePage.scss';

function App() {
  const [viewMenu, setViewMenu] = useState(false);
  const [visibilityStatus, setVisibilityStatus] = useState(true);
  const [action, _setAction] = useState({});
  const setAction = useCallback(attrs => _setAction(prev => ({ ...prev, ...attrs })), []);

  const { state } = useContext(Store);
  const { data: destinations } = useAction(getDestinations);
  const searchResult = useAction(processSearchView, action);

  useEffect(() => {
    AuthAction.syncLoggedIn();
    // eslint-disable-next-line
  }, []);

  const loadMore = useCallback((destination, id) => {
    return PublishedActions.loadMore(destination, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppContainer onVisibilityChange={setVisibilityStatus}>
      <Modal
        show={viewMenu}
        onHide={() => setViewMenu(false)}
        dialogClassName="modal-90w modal-full-h"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <MenuPanel user={state.user} />
        </Modal.Body>
        <ModalFooter>
          <div className="copyright-container">
            <p>Copyright 2020 - Presidencia de la Nación Argentina</p>
            <p>Todos los derechos reservados</p>
          </div>
        </ModalFooter>
      </Modal>

      <HeaderPanel
        destinations={destinations}
        moreCounter={state.published.moreCounter}
        fire={setAction}
        openMenu={() => setViewMenu(true)}
        destinationIdx={state.visibility.destinationVisible}
      />

      <Container className="home-page-container">
        {/* CONTENTS */}
        {action.view && action.view.id === views.default && action.destination && (
          <>
            <Tab.Container activeKey={action.destination.name}>
              <Tab.Content>
                {_.map(destinations, ({ destination, Component }) => (
                  <Tab.Pane key={destination} eventKey={destination}>
                    <Component
                      destination={destination}
                      fire={setAction}
                      list={state.published.list[destination]}
                      moreCounter={state.published.moreCounter[destination]}
                      onLoadMore={loadMore}
                      isVisible={action.destination.name === destination && visibilityStatus}
                    />
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </>
        )}

        {/* SEARCH */}
        {action.view && action.view.id === views.search && (
          <>
            {searchResult.loading && (
              <div className="loading-indicator">
                <span className="loader" />
                <p className="loading-indicator__text">Cargando</p>
              </div>
            )}

            {searchResult.data && <SearchPanel list={searchResult.data} />}

            {searchResult.error && <div>{searchResult.error}</div>}
          </>
        )}
      </Container>
    </AppContainer>
  );
}

export default App;

import logger from '../logger';

import tools from '../utils/tools';
import { dispatch, getState } from './Dispatch';

import Published from './Published';

const model = 'visibility';

const ACTIONS = {
  SYNC_ACTUAL_DESTINATION: `${model}SYNC_ACTUAL_DESTINATION`,
  CHANGE_DESTINATION_VISIBLE: `${model}CHANGE_DESTINATION_VISIBLE`
};

export const initialState = {
  [model]: {
    lastVisible: {},
    destinationVisible: undefined,
    destinationVisibleName: undefined
  }
};

export const reducer = {
  [ACTIONS.SYNC_ACTUAL_DESTINATION]: state => {
    const stateModel = state[model];

    return {
      ...state,
      [model]: {
        ...stateModel,
        lastVisible: {
          ...stateModel.lastVisible,
          [stateModel.destinationVisibleName]: tools.getActualTime()
        }
      }
    };
  },

  [ACTIONS.CHANGE_DESTINATION_VISIBLE]: (state, { destinationIdx, destinationName }) => {
    const stateModel = state[model];

    return {
      ...state,
      [model]: {
        ...stateModel,
        lastVisible: {
          ...stateModel.lastVisible,
          [stateModel.destinationVisibleName]: tools.getActualTime()
        },
        destinationVisible: destinationIdx,
        destinationVisibleName: destinationName
      }
    };
  }
};

const changeDestinationVisible = (destinationIdx, destinationName) => {
  dispatch({ type: ACTIONS.CHANGE_DESTINATION_VISIBLE, payload: { destinationIdx, destinationName } });

  const state = getState();
  const stateModel = state[model];

  const actualTime = tools.getActualTime();
  const lastVisible = stateModel.lastVisible[destinationName];
  const timeLimit = Number(localStorage.getItem('timeLimit'));

  if (!lastVisible || actualTime - lastVisible >= timeLimit) {
    Published.getUnreadList(destinationName);
  }
};

const setWindowIsActive = async status => {
  logger.log(`Window focus is ${status ? 'on' : 'off'}`);

  if (!status) {
    dispatch({ type: ACTIONS.SYNC_ACTUAL_DESTINATION });
  }
};

export default {
  changeDestinationVisible,
  setWindowIsActive
};

import _ from 'lodash';

import logger from '../logger';

import * as Api from '../services/Api';
import Event from '../services/Event';
import { addNotification, navigate } from '../services';

import { dispatch } from './Dispatch';

const baseAction = '$Auth_';

export const ACTIONS = {
  LOGGED_IN: `${baseAction}LOGGED_IN`,
  CLEAN_USER: `${baseAction}CLEAN_USER`,
  SYNC_VERSION: `${baseAction}SYNC_VERSION`
};

export const initialState = {
  user: undefined,
  context: undefined,
  errorLogin: undefined,
  version: undefined
};

export const reducer = {
  [ACTIONS.LOGGED_IN]: (state, { user, context, version }) => {
    return { ...state, user, context, version };
  },

  [ACTIONS.CLEAN_USER]: state => {
    return { ...state, user: undefined, context: undefined };
  },

  [ACTIONS.SYNC_VERSION]: (state, { version }) => {
    return { ...state, version };
  }
};

const login = async credential => {
  try {
    const res = await Api.login(credential);
    localStorage.setItem('token', res.token);
    dispatch({ type: ACTIONS.LOGGED_IN, payload: res });
    return res;
  } catch (err) {
    if (err.response && err.response.status === 403 && err.response.data && err.response.data.user) {
      throw err.response.data.user;
    } else {
      dispatch({ type: ACTIONS.CLEAN_USER });
      const errorDefault = 'Usuario y/o Contraseña inválida';
      const errorResult = _.get(err, 'response.data.message', errorDefault);
      return errorResult === 'Unauthorized' ? errorDefault : errorResult;
    }
  }
};

const syncLoggedIn = ({ stayPage } = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Api.loginSync({ stayPage, silence: true });
      dispatch({ type: ACTIONS.LOGGED_IN, payload: res });
      Event.emit('pn:logout');
      Event.emit('socket:logout');
      Event.emit('socket:login');
      if (res.user.firstSession) {
        logout();
        reject();
      }
      resolve(res);
    } catch (err) {
      if (err.statusCode === 401) {
        navigate('/login');
        reject(dispatch({ type: ACTIONS.CLEAN_USER }));
      }

      const res = await Api.syncVersion();
      dispatch({ type: ACTIONS.SYNC_VERSION, payload: res });
    }
  });
};

const logout = async () => {
  try {
    Event.emit('socket:logout');
    localStorage.removeItem('token');
    navigate('/login');
  } catch (err) {
    logger.log('logout error: ', err);
  }
};

const register = async user => {
  try {
    const res = await Api.register(user);
    addNotification({ message: 'Se ha registrado correctamente!', variant: 'success' });
    return res;
  } catch (err) {
    return { message: err.response.data.message };
  }
};

const registerVerify = async ({ username, email, phone }) => {
  if (username || email || phone) {
    return Api.registerVerify({ username, email, phone });
  }
  return {};
};
const changePassword = async (_id, newPassword, oldPassword) => {
  try {
    const res = await Api.publicPost(`auth/change-password`, { _id, newPassword, oldPassword });
    dispatch({ type: ACTIONS.LOGGED_IN, payload: res });
    addNotification({ message: res, variant: 'success' });
    return res;
  } catch (err) {
    addNotification({ message: err.business || err.message });
    throw err;
  }
};

export default {
  login,
  syncLoggedIn,
  logout,
  register,
  registerVerify,
  changePassword
};

import React, { useEffect } from 'react';

import { appName } from '../config';

import './terms.scss';

export default () => {
  /** Reset to the top scroll  */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="legal">
      <h2>Términos y condiciones</h2>

      <h3>Alcance</h3>
      <span>
        Los presentes Términos y Condiciones son aplicables a la aplicación de {appName} App tanto en su versión mobile
        como web (en adelante, U App). El registro y uso de en U App por parte de un usuario indica la aceptación de los
        presentes Términos y Condiciones (en adelante, TyC) y de la Política de Privacidad. El acceso a U App es con
        previo registro el cual queda a exclusiva aceptación por parte de un administrador de {appName}.{' '}
      </span>

      <h3>Usuarios, obligaciones, responsabilidad y condiciones</h3>
      <span>
        El Usuario deberá respetar estos Términos y Condiciones de Uso de la aplicación. El Usuario se obliga a usar U
        App de conformidad con estos Términos y Condiciones, en forma correcta y lícita. En caso contrario, el
        Administrador podrá suspender la cuenta del Usuario. El Usuario se compromete a:
        <ul>No acceder a datos restringidos o a intentar violar las barreras de seguridad para llegar a ellos.</ul>
        <ul>
          No realizar búsquedas de vulnerabilidades o explotación de las mismas para cualquier fin, ni divulgar
          información acerca de la detección de vulnerabilidades encontradas si las hubiere.
        </ul>
        <ul>
          Comunicar al Administrador toda información a la que tenga acceso que pudiera implicar un compromiso a la
          seguridad de la información o los servicios digitales.
        </ul>
        La adecuada utilización de todos los recursos de Internet es, sin excepción, de entera responsabilidad del
        Usuario de los Servicios Digitales.
      </span>

      <h3>Uso General</h3>
      <span>
        Requiere inscripción previa a través de un formulario de registro que deberá ser completado por el Usuario. De
        esta forma las personas podrán inscribirse y crear una contraseña que les permitirá acceder a la plataforma.
        <br />
        La información personal suministrada por el Usuario al momento de inscribirse en el sitio web está protegida con
        una clave y sólo podrá ser modificada por el mismo Usuario y/o por un administrador del sistema.
        <br />
        El sitio web se reserva el derecho de realizar validaciones en relación a la información brindada por el Usuario
        al momento de la inscripción. En caso de que la información brindada no pueda validarse, el Administrador se
        reserva el derecho de no dar aceptación de acceso a ese Usuario.
        <br />
        Al momento de la inscripción, el Usuario asume el compromiso y la responsabilidad de:
        <ul>No proporcionar información personal falsa ni crear cuentas a nombre de terceros.</ul>
        <ul>No crear más de una cuenta personal.</ul>
        <ul>
          No crear otra cuenta sin permiso expreso del Administrador, en caso de que este último haya inhabilitado la
          cuenta original.
        </ul>
        <ul>Mantener la información de contacto exacta y actualizada.</ul>
        <ul>No compartir la contraseña ni permitir a otra persona acceda a su cuenta.</ul>
        <ul>El Usuario se compromete a notificar al Administrador ante cualquier uso no autorizado de su clave.</ul>
        El Administrador se reserva el derecho de rechazar cualquier solicitud de inscripción o de cancelar un registro
        previamente aceptado.
      </span>

      <h3>Propiedad intelectual</h3>
      <span>
        La expresión de ideas, procedimientos, métodos de operación y conceptos matemáticos de conformidad con lo
        establecido en el artículo 1º de la Ley 11.723 y sus modificatorias, así como las marcas, avisos, nombres
        comerciales, publicidad, dibujos, diseños, logotipos, textos, entre otros, que aparecen en los Servicios
        Digitales son propiedad del Administrador, excepto cuando se declare lo contrario.
      </span>

      <h3>Modificación de los Términos y Condiciones</h3>
      <span>
        El Administrador se reserva el derecho de modificar estos Términos y Condiciones en cualquier momento,
        manteniendo actualizados los mismos accedidos a través de U App bajo el menú de personalización.
        <br />
        Ley aplicable y jurisdicción del sitio web
        <br />
        Los Términos y Condiciones de Uso aquí presentados se rigen por las leyes de la República Argentina.
      </span>

      <h3>Política de privacidad</h3>
      <span>Consulte el apartado especial para políticas de privacidad.</span>

      <h3>Información al Usuario titular de los datos personales</h3>
      <span>
        El Usuario que utilice el sitio web podrá proporcionar algunos datos personales a efectos de mantenerse en
        contacto y recibir información respecto de servicios que se pongan a disposición de la persona en relación a los
        servicios utilizados.
        <br />
        El Usuario decide voluntariamente proporcionar sus datos personales, debiendo brindar datos veraces, exactos y
        completos. La inexactitud de los mismos puede suponer dificultades para establecer un vínculo directo con el
        Administrador.
        <br />
        Los datos recolectados tendrán por finalidad:
        <ul>Habilitar los registros de Usuario por parte del Administrador.</ul>
        <ul>Obtener métricas de uso y niveles de servicio de la aplicación.</ul>
        Consulte más información en el apartado especial para políticas de privacidad.
      </span>

      <h3>Seguridad</h3>
      <span>
        El responsable de infraestructura y base de datos declara que adopta las medidas técnicas y organizativas que
        resulten necesarias para garantizar la seguridad y confidencialidad de los datos personales de modo de evitar su
        tratamiento no autorizado.
        <br />
        Para proteger a los datos de la aplicación y a los Usuarios de cualquier modificación, divulgación o destrucción
        no autorizada de los mismos se toman, entre otros, los siguientes recaudos:
        <ul>
          Entorno seguro para el equipamiento y los sistemas utilizados para almacenar los datos de los usuarios.
          Políticas definidas en materia de recolección, almacenamiento y tratamiento de datos de registro de usuario y
          uso, incluyendo medidas de seguridad física y controles de acceso a los sistemas que emplean los activos
          digitales. Acceso restrictivo a los datos por parte de terceros, basados en el principio de “necesidad de
          saber” y una adecuada separación de funciones, asegurando la suscripción y el cumplimiento de las condiciones
          de confidencialidad requeridas por los datos bajo tratamiento. Otras medidas de seguridad que resulten de
          aplicación en cada caso, conforme a la normativa vigente.
        </ul>
      </span>

      <h3>Enlaces con otros sitios, productos y servicios</h3>
      <span>
        U App puede contener enlaces hacia sitios, productos y/o servicios de terceros, no obstante, el Administrador no
        administra ni controla esos sitios, productos y/o servicios y no es responsable de sus contenidos ni de
        cualquier daño y perjuicio que tales contenidos, productos y servicios pudieran causar, por lo que se recomienda
        la lectura de los Términos y Condiciones de cada uno de ellos.
        <br />
        Datos sobre el dispositivo: es posible que el administrador deba recoger datos específicos sobre el dispositivo
        del Usuario como, por ejemplo, el modelo de equipo, la versión del sistema operativo, los identificadores
        únicos. Asimismo, el Administrador podrá asociar los identificadores del dispositivo o número de teléfono del
        Usuario con su cuenta en el sistema de identificación único.
        <br />
        Cookies e identificadores anónimos: es posible utilizar diferentes tecnologías para recoger y almacenar datos
        cuando el Usuario accede a un servicio, incluyendo el envío de una o varias cookies o de identificadores
        anónimos a su dispositivo.
      </span>
    </div>
  );
};
